






import {Vue, Component, Prop} from 'vue-property-decorator';

import Pagination from '@/core/support/pagination/Pagination';

@Component
export default class DataTableCurrentLimit extends Vue {
  /**
   * Props
   */
  @Prop() private pagination!: Pagination;

  /**
   * Getters
   */
  private get currentLimitText(): string {
    if (this.pagination.currentLimit === this.pagination.getTotal()) {
      return `Pozycji: ${this.pagination.getTotal().toString()}`;
    }

    return `Pozycji: ${this.pagination.currentLimit} z ${this.pagination.getTotal()}`;
  }
}
